import revive_payload_client_8rTs2GCQLx from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.18.13_typescript@5.2.2_vite@4.5.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2ReWLPwh5D from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.18.13_typescript@5.2.2_vite@4.5.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3fF1aMQ7L3 from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.18.13_typescript@5.2.2_vite@4.5.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_gAntyMBeyP from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/@nuxtjs+supabase@1.1.4/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_vLHAbPVeh8 from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.18.13_typescript@5.2.2_vite@4.5.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/yuyue/Documents/Projects/embbay/packages/web/.nuxt/components.plugin.mjs";
import prefetch_client_N2Ws0w1IAE from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.18.13_typescript@5.2.2_vite@4.5.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_redirect_h38m5tY6KN from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/@nuxtjs+supabase@1.1.4/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import colors_2Gaa23rWNL from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/@nuxt+ui@2.11.0_nuxt@3.8.2_vite@4.5.2_vue@3.4.15/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_STsxLw8EHl from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_CEWCpK3vNU from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.18.13_typescript@5.2.2_vite@4.5.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_NryQIw76Yz from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.18.13_typescript@5.2.2_vite@4.5.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
export default [
  revive_payload_client_8rTs2GCQLx,
  unhead_2ReWLPwh5D,
  router_3fF1aMQ7L3,
  supabase_client_gAntyMBeyP,
  payload_client_vLHAbPVeh8,
  components_plugin_KR1HBZs4kY,
  prefetch_client_N2Ws0w1IAE,
  auth_redirect_h38m5tY6KN,
  colors_2Gaa23rWNL,
  plugin_client_STsxLw8EHl,
  chunk_reload_client_CEWCpK3vNU,
  check_outdated_build_client_NryQIw76Yz
]