<template>
  <CookieConsentPopup />
  <Suspense>
    <NuxtPage />
    <template #fallback>
      <FullscreenLoading />
    </template>
  </Suspense>
  <UNotifications />
</template>
