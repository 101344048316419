<script setup lang="ts">
const LOCAL_STORAGE_KEY = "cookie_consent";
const cookieConsentLocalStorage = useLocalStorage(LOCAL_STORAGE_KEY, false);
const cookieConsent = ref(cookieConsentLocalStorage.value);
</script>

<template>
  <div
    v-if="!cookieConsent"
    class="fixed bottom-2 left-2 z-50 max-w-xs rounded bg-slate-100 p-4 shadow-lg"
  >
    <button class="-ml-3 -mt-4 block" @click="cookieConsent = true">x</button>
    <div>
      <p class="text-sm">
        This website uses necessary cookies to ensure you get the best
        experience on our website.
        <a
          class="text-blue-500 underline"
          href="/public/docs/cookie-policy"
          target="_blank"
          >Learn more</a
        >
      </p>
      <div class="text-xs">
        If you don't want to see this message again, click the button below.
        Your preference will be saved in your browser (local storage).
      </div>
      <UButton
        class="mt-2"
        title="Accept and do not show again"
        @click="
          cookieConsent = true;
          cookieConsentLocalStorage = true;
        "
      >
        Accept necessary cookies
      </UButton>
    </div>
  </div>
</template>
